import React, { useMemo } from 'react';
import { Table, TablePaginationConfig } from 'antd';

import { Container } from './styles';
import { PlusIcon } from '@heroicons/react/outline';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

interface props {
  data: any[];
  columns: any[];
  pagination: TablePaginationConfig;
  rowKey: string;
  loading: boolean;
  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
  ) => void;
  onClickRow?: (value: any) => void;
  renderTableActions?: (record: any, row: any, index: any) => void;
  history?: any;
  addNewRouter?: string;
  hasAddnew?: boolean;
  onAddNew?: () => void;
}

const TableComponent: React.FC<props> = ({
  data,
  columns,
  pagination,
  rowKey,
  loading,
  history,
  addNewRouter,
  hasAddnew = false,
  onChange,
  onClickRow,
  renderTableActions,
  onAddNew,
}) => {
  const searchResults = () => {
    if (!pagination.pageSize || !pagination.current) {
      return '';
    }

    if (pagination.total === 0) {
      return '';
    }

    const firstItem =
      pagination.pageSize * pagination.current - pagination.pageSize + 1;

    return `Showing ${firstItem} to ${
      pagination.pageSize * pagination.current
    } of ${pagination.total} entries`;
  };

  const columnsData = useMemo(() => {
    return columns.filter((item) => {
      item.sortDirections = ['descend', 'ascend'];

      if (item.is_searchable) {
        item.sorter = () => null;
      }

      return item;
    });
  }, [columns]);

  return (
    <Container hasAddnew={hasAddnew}>
      {hasAddnew && (
        <div className="flex items-center absolute right-0 mt-4 pt-1 z-10 mr-4">
          <span
            onClick={() => (onAddNew ? onAddNew() : history.push(addNewRouter))}
            className={`flex items-center text-xs dark:text-iron  ${
              loading ? 'not-allowed' : 'cursor-pointer'
            }`}>
            Add new <PlusIcon className="w-5 h-5" />
          </span>
        </div>
      )}
      {!loading && (
        <span className="dark:text-iron text-xs absolute mt-6">
          {searchResults()}
        </span>
      )}
      {pagination.total === 0 && <div style={{ paddingTop: 50 }} />}
      <Table
        dataSource={data}
        columns={[
          ...columnsData,
          {
            title: '',
            key: 'action',
            fixed: 'right',
            width: 0,
            render: (record, row, index) => {
              if (!renderTableActions) return null;

              return renderTableActions(record, row, index);
            },
          },
        ]}
        pagination={{
          ...pagination,
          position: ['topRight', 'bottomRight'],
          showSizeChanger: true,
          hideOnSinglePage: false,
        }}
        rowKey={rowKey}
        tableLayout="auto"
        loading={loading}
        onChange={onChange}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              if (!onClickRow) return;

              onClickRow({ record, rowIndex });
            },
          };
        }}
      />
      {hasAddnew && (
        <div className="absolute right-0 -mt-10 mr-4">
          <span
            onClick={() => (!loading ? history.push(addNewRouter) : null)}
            className={`flex items-center text-xs dark:text-iron  ${
              loading ? 'not-allowed' : 'cursor-pointer'
            }`}>
            Add new <PlusIcon className="w-5 h-5" />
          </span>
        </div>
      )}
    </Container>
  );
};

export default TableComponent;
