import GlobalRepository from './global';
import ApiRepository from './api';
import AiRepository from './ai';
import AiForecastRepository from './ai/forecast';
import ApiReportsScheduleRepository from './api/reports-schedule';
import MaintenanceModeRepository from './api/maintenance-mode';
import HotelRatingChannelRepository from './api/hotel-rating-channel/google-rating';
import AutopilotEmailsRepository from './api/price-recommendation/autopilot-emails';
import BaseRoomRateRepository from './api/price-recommendation/base-room-rate';
import PriceRecommendationRepository from './api/price-recommendation/price-recommendation';
import AiAbsoluteErrorRepository from './ai/absolute-error';
import ClearCacheRepository from './api/clear-cache';

export default class Repository {
  global: GlobalRepository;
  api: ApiRepository;
  apiReportsSchedule: ApiReportsScheduleRepository;
  ai: AiRepository;
  aiForecast: AiForecastRepository;
  aiAbsoluteError: AiAbsoluteErrorRepository;
  maintenanceMode: MaintenanceModeRepository;
  hotelRatingChannelRepository: HotelRatingChannelRepository;
  autopilotEmailsRepository: AutopilotEmailsRepository;
  baseRoomRateRepository: BaseRoomRateRepository;
  priceRecommendationRepository: PriceRecommendationRepository;
  clearCacheRepository: ClearCacheRepository;

  constructor() {
    this.global = new GlobalRepository();
    this.api = new ApiRepository();
    this.apiReportsSchedule = new ApiReportsScheduleRepository();
    this.ai = new AiRepository();
    this.aiForecast = new AiForecastRepository();
    this.maintenanceMode = new MaintenanceModeRepository();
    this.hotelRatingChannelRepository = new HotelRatingChannelRepository();
    this.autopilotEmailsRepository = new AutopilotEmailsRepository();
    this.baseRoomRateRepository = new BaseRoomRateRepository();
    this.priceRecommendationRepository = new PriceRecommendationRepository();
    this.aiAbsoluteError = new AiAbsoluteErrorRepository();
    this.clearCacheRepository = new ClearCacheRepository();
  }
}
